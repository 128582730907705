import { MicroCopyContext } from "@edenred/micro-copy";
import { Typography } from "@mui/material/";
import { graphql, PageProps } from "gatsby";
import { useContext } from "react";
import Button from "../components/Button";
import ErrorPage from "../components/ErrorPage";

type Props = PageProps<Pick<GatsbyTypes.Query, "contentfulSite">>;

export default function ServerErrorPage({ data }: Props) {
  const genericClient = data.contentfulSite?.genericClient;
  const getMicroCopy = useContext(MicroCopyContext);

  return (
    <ErrorPage
      homePath={genericClient?.path || "/"}
      client={genericClient}
      title={getMicroCopy("error.server-error.title")}
      description={getMicroCopy("error.server-error.description")}
    >
      <Typography variant="h4" component="h1" sx={{ margin: 3 }}>
        {getMicroCopy("error.server-error.title")}
      </Typography>
      <Button href={genericClient?.path || "/"} size="large">
        {getMicroCopy("general.back")}
      </Button>
    </ErrorPage>
  );
}

export const pageQuery = graphql`
  query ServerErrorPageQuery {
    contentfulSite {
      genericClient {
        ...Client
      }
    }
  }
`;
